import { format } from "date-fns/format";
import PropTypes from "prop-types";
import { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";
import SelectDropdown from "components/Common/FilterSelects/SelectDropdown";
import SearchSingleSelectFilter from "components/Lists/FilterSelect/Filters/SearchSingleSelectFilter";
import FilterSection from "pages/Discover/Charts/Sidebar/FilterSection";

import { DEFAULT_CATEGORY, DEFAULT_COUNTRY } from "constants/charts";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  mobileModalButton: {
    display: "block",
    marginTop: "1rem",
    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
  containerSections: {
    marginRight: 0,
    [ScreenSizes.mdAndAbove]: {
      marginRight: "1rem",
    },
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "0.5rem",
    borderLeft: `6px solid ${colours.darkishBlue}`,
    minHeight: 50,
    padding: "0.5rem 0",
  },
  dateContainer: {
    display: "block",
    position: "relative",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "1.125rem",
    color: colours.darkishBlue,
    margin: 0,
  },
  subTitle: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    color: "var(--color-neutral-d5)",
    margin: 0,
  },
  sectioncontainer: {
    display: "flex",
    gap: "0.25rem",
    flexDirection: "column",
    background: colours.white,

    marginBottom: "2rem",
    ":last-child": {
      marginBottom: 0,
    },
    [ScreenSizes.mdAndAbove]: {
      marginBottom: 0,
    },
  },
  lockIcon: {
    ...gStyles.avalonBold,
    position: "absolute",
    border: `0.5px solid ${colours.darkishBlue}`,
    color: colours.selectedDarkBlue,
    background: colours.selectedLightBlue,
    borderRadius: "25px",
    width: "100%",
    textAlign: "Center",
    top: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: "0",

    ":hover": {
      cursor: "pointer",
    },
  },
};

const selectStyles = {
  input: {
    width: "100%",
  },
  options: {
    maxHeight: "20rem",
    overflow: "auto",
    padding: "1rem 0",
  },
};

const categoryStyles = {
  container: {
    gridTemplateRows: "1fr",
  },
  outer: {
    marginLeft: 0,
    marginTop: 0,

    [ScreenSizes.mdAndAbove]: {
      marginTop: -300,
      marginLeft: -260,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const PLATFORM_DETAILS =
  "Apple Podcasts and Spotify have their own unique podcast rankings, based on their users' listenership. These charts are segmented within individual countries and categories specific to each platform.";
const COUNTRY_DETAILS =
  "Each platform organizes its charts by country, highlighting the top podcasts among local audiences. Note: In countries with limited listenership, specific charts may be small or even empty.";
const CATEGORY_DETAILS =
  "Platforms feature multiple charts across various categories and subcategories to showcase the top shows within specific genres or topics. Additionally, you can view overall charts by country, which highlight the top podcasts regardless of category.";

const CHARTS_ARTICLE_LINK =
  "https://support.podchaser.com/en/articles/9237143-charts-on-podchaser";

const Filters = (props) => {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    platformOptions,
    countryFilter,
    categoryFilter,
    instantUpdate,
    handleViewCharClick,
    platform,
    country,
    category,
    date,
    setFilters,
    passedPopUpStyles,
  } = props;

  const handlePlatformUpdate = useCallback(
    (selectedTab) => {
      setFilters &&
        setFilters((prevState) => ({
          ...prevState,
          platform: selectedTab,
          country: DEFAULT_COUNTRY,
          category: DEFAULT_CATEGORY,
        }));
    },
    [setFilters]
  );

  const renderDetails = ({ details, learnMoreLink, wrapperRef }) => {
    return (
      <p className={css(styles.para)} ref={learnMoreLink ? null : wrapperRef}>
        {details}
      </p>
    );
  };

  return (
    <>
      <div className={css(styles.sectioncontainer)}>
        <h4 className={css(styles.subTitle)}>Chart</h4>
        <div className={css(styles.containerSections)}>
          <FilterSection
            title="Platform"
            learnMoreTitle="Charts"
            learnMoreLink={CHARTS_ARTICLE_LINK}
            type="chartPlatform"
            bypassForAll
            renderPopupDetails={(wrapperRef) =>
              renderDetails({ details: PLATFORM_DETAILS, wrapperRef })
            }
            passedPopUpStyles={passedPopUpStyles}
          >
            <SelectDropdown
              options={platformOptions}
              selected={platform}
              onChange={(selectedTab) => {
                handlePlatformUpdate(selectedTab);
                instantUpdate && instantUpdate(selectedTab, "platform");
              }}
              ariaLabel="Platforms"
              label="Select a Platform"
              styles={selectStyles}
              defaultSelectedId={"all"}
            />
          </FilterSection>
          <FilterSection
            title="Country"
            type="chartCountry"
            learnMoreTitle="Charts"
            bypassForAll
            learnMoreLink={CHARTS_ARTICLE_LINK}
            renderPopupDetails={(wrapperRef) =>
              renderDetails({ details: COUNTRY_DETAILS, wrapperRef })
            }
            passedPopUpStyles={passedPopUpStyles}
          >
            <SearchSingleSelectFilter
              selected={country}
              filter={countryFilter}
              onChange={(selectedTab) => {
                setFilters &&
                  setFilters((prevState) => ({
                    ...prevState,
                    country: selectedTab,
                  }));
                instantUpdate && instantUpdate(selectedTab, "country");
              }}
              ariaLabel="Countries"
              label="Select a Country"
              styles={selectStyles}
              defaultSelectedId={"all"}
            />
          </FilterSection>
          <FilterSection
            type="chartCategory"
            title="Category"
            renderPopupDetails={(wrapperRef) =>
              renderDetails({ details: CATEGORY_DETAILS, wrapperRef })
            }
            passedPopUpStyles={categoryStyles}
            learnMoreLink={CHARTS_ARTICLE_LINK}
            learnMoreTitle="Charts"
            bypassForAll
          >
            <SearchSingleSelectFilter
              selected={category}
              filter={categoryFilter}
              onChange={(selectedTab) => {
                setFilters &&
                  setFilters((prevState) => ({
                    ...prevState,
                    category: selectedTab,
                  }));
                instantUpdate && instantUpdate(selectedTab, "category");
              }}
              ariaLabel="Categories"
              label="Select a Category"
              styles={selectStyles}
              defaultSelectedId={"all"}
            />
          </FilterSection>
        </div>
      </div>

      <div className={css(styles.mobileModalButton)}>
        <StandardButton
          label={"View chart"}
          variation="pink"
          onClick={() =>
            handleViewCharClick({
              platform: platform,
              country: country,
              category: category,
              date: format(date, "yyyy-MM-dd"),
            })
          }
          flat
          dataId="modal-confirmation-action-confirmation"
        />
      </div>
    </>
  );
};

Filters.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default Filters;
